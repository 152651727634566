@mixin heading-block-h4 {
  @include font-special;

  display: inline-block;
  font-size: 1.8rem;
  margin-bottom: 1rem;

  @include media-breakpoint-up(sm) {
    font-size: 2rem;
  }
  @include media-breakpoint-up(md) {
    font-size: 2.3rem;
  }
  @include media-breakpoint-up(lg) {
    font-size: 2.5rem;
  }
  @include media-breakpoint-up(xl) {
    font-size: 3rem;
  }
}
