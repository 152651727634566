$cloudinaryThemeCloudBaseUrl: 'https://res.cloudinary.com/difp9jq5j/image/upload/themes/carreisen-ostschweiz/';
@import 'styles/helpers/global_variables';

.root {
  @include content-block;

  position: relative;
  height: 700px;
  max-height: 75vh;

  .content {
    padding: 0 0 $header-content-padding-y 0;
    position: absolute;
    bottom: 0;
    color: $white;
    @include readability-text-shadow;

    .title {
      padding-left: $header-content-padding-x;
      word-break: break-word;
      margin-bottom: 1.9rem;
      user-select: none;
      line-height: 1;
      @include jumbo-title;

      @include media-breakpoint-up(sm) {
        padding-left: $header-content-padding-x-sm;
      }

      @include media-breakpoint-up(xl) {
        padding-left: $header-content-padding-x-xl;
      }
    }

    .text {
      padding-left: $header-content-padding-x;
      @include jumbo-subtitle;

      @include media-breakpoint-up(sm) {
        padding-left: $header-content-padding-x-sm;
      }

      @include media-breakpoint-up(xl) {
        padding-left: $header-content-padding-x-xl;
      }
    }
  }
}
