$cloudinaryThemeCloudBaseUrl: 'https://res.cloudinary.com/difp9jq5j/image/upload/themes/carreisen-ostschweiz/';
@import 'styles/helpers/global_variables';

$fontColor: #fff;

.fakeSelect {
  position: relative;
  display: block;
  width: 100%;
  padding: $input-padding-y $input-padding-x;
  font-size: 0.9rem;
  font-weight: 400;
  font-style: italic;
  line-height: 1.3;
  color: $filter-form-input-placeholder-color;
  border: 1px solid $filter-form-input-border-color;
  cursor: pointer;
  user-select: none;

  &::after {
    // chevron down
    content: '';
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='" + $filter-form-input-chevron-color + "' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-size: 1rem 0.8rem;
    position: absolute;
    right: 0.75rem;
    z-index: 6;
    top: 0.8rem;
    width: 1rem;
    height: 0.8rem;
    transition-duration: 0.2s;
    transition-property: transform;
  }

  &:hover,
  &.active {
    color: $filter-form-input-placeholder-hover-color;

    &::after {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='" + $filter-form-input-chevron-hover-color + "' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
    }
  }

  &.active::after {
    transform: rotate(-180deg);
  }
}

.filterOptionsWrap {
  display: none;

  &.active {
    display: block;
  }
}
