@import 'typography';
@import 'elements';
@import 'components';

@mixin theme-background {
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    background-repeat: repeat;
    position: absolute;
    width: calc(100vh* 10);
    height: calc(100vh* 10);
    top: 0;
    left: 0;
    transform: translate(-50%, -50%) rotate(60deg);
    opacity: 0.5;
    z-index: -1;
    transform-origin: center;
    background-color: #FFFFFF;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='598' height='71.8' viewBox='0 0 1000 120'%3E%3Cg fill='none' stroke='%23F0F0F0' stroke-width='3.8' %3E%3Cpath d='M-500 75c0 0 125-30 250-30S0 75 0 75s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 45c0 0 125-30 250-30S0 45 0 45s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 105c0 0 125-30 250-30S0 105 0 105s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 15c0 0 125-30 250-30S0 15 0 15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500-15c0 0 125-30 250-30S0-15 0-15s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3Cpath d='M-500 135c0 0 125-30 250-30S0 135 0 135s125 30 250 30s250-30 250-30s125-30 250-30s250 30 250 30s125 30 250 30s250-30 250-30'/%3E%3C/g%3E%3C/svg%3E");
  }
}

@mixin branded-button-font {
  //font-family: $font-family-sans-serif;
  //font-weight: 700;
  @include font-uppercase-bold;
}

@mixin travel-intro-highlights-root {
  background-color: $quaternary;
  color: $white;
  padding: 1.5rem 2rem 1.5rem 1rem;
  border-radius: 0 0 2.5rem 0;
  margin-right: 0.5rem;
}

@mixin travel-highlights-list {
  @include branded-list(0.3rem);
  @include font-uppercase;
  padding-bottom: 0.5rem;
  margin-bottom: 0.5rem;

  &:not(:last-of-type) {
    border-bottom: 1px dashed $white;
  }

  li {
    line-height: 1.5rem;
    margin-bottom: 0.2rem;
  }
}
