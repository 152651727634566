$cloudinaryThemeCloudBaseUrl: 'https://res.cloudinary.com/difp9jq5j/image/upload/themes/carreisen-ostschweiz/';
@import 'styles/helpers/global_variables';

.root {
  // position
  position: absolute;
  z-index: 1;
  top: 1.25rem;
  right: 0;
  padding-right: 0.45rem;

  font-size: 0.875rem;
  line-height: 2.125rem;
  color: $white;
  background: $signal;

  @include teaser-flag;
}
