@mixin image-block-font {
  font-family: $theme-font-bold;
  @include readability-text-shadow;

  // font size
  font-size: 3.5rem;
  @include media-breakpoint-up(md) {
    font-size: 4.5rem;
  }
  @include media-breakpoint-up(xl) {
    font-size: 6rem;
  }
}
