$cloudinaryThemeCloudBaseUrl: 'https://res.cloudinary.com/difp9jq5j/image/upload/themes/carreisen-ostschweiz/';
@import 'styles/helpers/global_variables';

.root {
  @include content-block;

  margin: 3rem 0;
  position: relative;
  padding-top: max(60%, 326px);
  height: 0;
  width: 100%;

  iframe {
    position: absolute;
    border: none;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
}
