// scss-docs-start main-nav
$headerHeight: 90px;
$navHeight: 90px;
// scss-docs-end main-nav

// scss-docs-start page-header-variables
$header-slider-content-padding-bottom: 3.5rem;
// scss-docs-end page-header-variables

// scss-docs-start filter
$filter-form-bg-color: $primary;
$filter-form-color: $white;
$filter-form-title-margin: 0 !default;
$filter-form-inputs-margin: 0 !default;
$filter-form-submit-margin: 0 !default; // lead-form
$filter-form-info-margin: 0 !default; // filter-nav (filter page)
$filter-form-input-bg-color: transparent !default;
$filter-form-input-border-color: $white;
$filter-form-input-placeholder-color: $white;
$filter-form-input-placeholder-hover-color: $white;
$filter-form-input-text-color: $white;
$filter-form-input-chevron-color: encodecolor($white);
$filter-form-input-chevron-hover-color: encodecolor($gray-400);
$filter-form-calendar-year-label-bg-color: $gray-800;
$filter-form-calendar-month-hover-bg-color: $gray-800;
$filter-form-calendar-month-active-bg-color: $secondary;
$filter-form-calendar-month-disabled-opacity: 0.5;
$filter-form-badge-bg-color: $gray-700;
$filter-lead-form-submit-btn-margin-top: 0.5rem !default;
$filter-lead-form-submit-btn-font-family: $theme-font-regular !default;
$filter-lead-form-submit-btn-font-size: 4rem;
$filter-lead-form-submit-btn-color: $white;
$filter-lead-form-submit-btn-hover-color: $signal !default;
// scss-docs-end filter

// scss-docs-start heading-block
$cms-heading-block-padding-top: 3.9rem;
// scss-docs-end heading-block

// scss-docs-start contact-banner
$contact-banner-bg-color: $primary;
$contact-banner-color: $white !default;
// scss-docs-end contact-banner

// scss-docs-start newsletter-block
$newsletter-block-font-family: inherit !default;
$newsletter-block-title-color: $white;
$newsletter-block-color: $tertiary !default;
$newsletter-block-color-hover: $black !default;
// scss-docs-end newsletter-block

// scss-docs-start travel-pricing-block
$pricing-block-margin-top: 0 !default;
$pricing-block-margin-bottom: 3rem !default;
$pricing-block-background-color: inherit !default;
$pricing-block-title-margin-top: 3rem;
$pricing-block-title-margin-bottom: 1rem;
$pricing-block-info-outer-padding: 0 2.5rem 0 0;
$pricing-block-info-outer-margin: 0 0 3rem 0 !default;
$pricing-block-info-outer-margin-print: 0 0 1rem 0 !default;
$pricing-block-info-inner-padding: 0 0 0 0 !default;
$pricing-block-info-inner-padding-print: $pricing-block-info-inner-padding !default;
$pricing-block-info-pricing-info-color: auto !default;
$pricing-block-services-outer-padding: 0 0 0 0 !default;
$pricing-block-services-outer-padding-print: $pricing-block-services-outer-padding !default;
$pricing-block-services-ul-margin: 0 0 3rem 0 !default;
// scss-docs-end travel-pricing-block
